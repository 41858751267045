import React, { useEffect, FC } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';

export const LogoutEnd: FC = () => {
    useEffect(() => {
        microsoftTeams.initialize();

        microsoftTeams.authentication.notifySuccess('logged out');
    }, []);

    return <></>;
};
