import React, { FC, useEffect, useState } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { useNavigate } from 'react-router-dom';

export const LogoutStart: FC = () => {
    const history = useNavigate();
    const [errorMsg, setErrorMsg] = useState<string>(undefined);

    useEffect(() => {
        microsoftTeams.initialize();

        microsoftTeams.authentication.authenticate({
            url: `${window.location.origin}/logout?teams=true`,
            width: 600,
            height: 535,
            successCallback(result) {
                history(`/teamsauth`);
            },
            failureCallback(reason) {
                setErrorMsg(reason);
            }
        });
    }, []);

    return (
        <div>
            {errorMsg && `Failure: ${errorMsg}`}
        </div>
    );
};
