import React, { FC, useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';

import { getHashParameters } from '../tools/authTools';
import { localStorage } from '../tools/storage';

import { GlobalLoader } from './GlobalLoader';

export const TeamsAuthEnd: FC<React.PropsWithChildren<unknown>> = () => {
    useEffect(() => {
        microsoftTeams.initialize();

        const hashParams = getHashParameters(window.location.hash);
        if (hashParams['error']) {
            // Authentication/authorization failed
            microsoftTeams.authentication.notifyFailure(hashParams['error']);
        } else if (hashParams['access_token']) {
            // Get the stored state parameter and compare with incoming state
            // This validates that the data is coming from Azure AD
            let expectedState = localStorage.getItem('simple.state');
            if (expectedState !== hashParams['state']) {
                // State does not match, report error
                microsoftTeams.authentication.notifyFailure('StateDoesNotMatch');
            } else {
                // Success: return token information to the tab
                microsoftTeams.authentication.notifySuccess(JSON.stringify(hashParams));
            }
        } else {
            // Unexpected condition: hash does not contain error or access_token parameter
            microsoftTeams.authentication.notifyFailure('UnexpectedFailure');
        }
    }, []);

    return (
        <GlobalLoader isLoading />
    );
}
